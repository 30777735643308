import TYPES from '@/types';

import { v4 } from 'uuid';

// Application
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import CreateInvestmentFundHireCommand
  from '@/modules/my-investment/allianz/access-more-strategies/application/commands/create-investment-fund-hire-command';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import {
  CustomerContractedInvestmentProductsStateManager,
} from '@/modules/flagship/customer-contracted-investment-products/domain/state/customer-contracted-investment-products-state-manager';
import {
  InvestmentProductsStateManager,
} from '@/modules/flagship/catalogs/investment-products/domain/state/investment-products-state-manager';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import { InvestorProfileStateManager }
  from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class CreateInvestorGoalWizardViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.CREATE_INVESTMENT_FUND_HIRE_COMMAND)
  private readonly create_investment_fund_hire_command!: CreateInvestmentFundHireCommand;

  @Inject(TYPES.CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_STATE_MANAGER)
  private readonly state_manager!: CustomerContractedInvestmentProductsStateManager;

  @Inject(TYPES.INVESTMENT_PRODUCTS_STATE_MANAGER)
  private readonly product_state_manager!: InvestmentProductsStateManager;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
  private readonly investor_profile_state_manager_service!: InvestorProfileStateManager;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.create-investor-goal-wizard';

  STEPS = [
    'GoalFlowSelector',
  ];

  STEPS_EMERGENCY_FUND = [
    'EmergencyFundVideo',
    'EmergencyFundDefinePlan',
    'EmergencyFundContributions',
    'EmergencyFundSummary',
  ];

  STEPS_RETIREMENT_FUND = [
    'RetirementFundVideo',
    'RetirementFundDefinePlan',
    'RetirementFundAge',
    'RetirementFundContributions',
    'RetirementFundSummary',
  ];

  STEPS_CUSTOM = [
    'CustomDefinePlan',
    'CustomContributions',
    'CustomSummary',
  ];

  STEPS_BUDGET = [
    'DefineInvestmentTerm',
  ]

  step_names: Array<string> = [];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  associated_product_name = '';

  associated_product_id = '';

  investor_goal_state = this.create_investor_goal_state_manager.state;

  more_strategies_state = this.manager_service.getAccessMoreStrategiesState();

  goal_type = '';

  investor_profile = this.investor_profile_state_manager_service.state.investor_profile;

  private readonly view: any;

  constructor(view: any) {
    this.view = view;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.step_names = this.step_names.concat(this.translate('steps'));
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  goalFlowSelected = (selected_option: string) => {
    this.goal_type = selected_option;
    this.STEPS.length = 1;
    if (selected_option === 'unique') {
      this.STEPS.push('GoalTypeSelector');
      this.step_names = this.step_names.concat(this.translate('steps_unique'));
      this.nextStep();
    } else if (selected_option === 'budget') {
      this.restoreStateBudget();
      this.validateWealthContractedProduct();
    } else if (selected_option === 'we_support_you') {
      this.view.$emit('supportYouSelected');
    }
  }

  nextStep = () => {
    if (this.current_step < this.STEPS.length - 1) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.view.endProcess();
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  restoreSteps = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
    this.step_names = this.step_names.concat(this.translate('steps'));
  }

  endProcess = async () => {
    if (this.goal_type === 'budget') {
      this.restoreSteps();
    }
    return true;
  }

  fixedTimeChange = (fixed_time_adjusted: number) => {
    if (this.investor_goal_state.investor_goal_type === 'custom') {
      this.validateContractedProducts();
    }
  }

  strategyChange = (strategy: string) => {
    this.STEPS = ['GoalFlowSelector'].concat(this.STEPS_BUDGET);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.step_names = this.translate('steps')
      .concat(this.translate('steps_budget'));
    this.more_strategies_state.customer_agreement.id = '';
    if (strategy === 'SWSRFP') {
      this.step_names = this.step_names
        .concat(this.translate('steps_budget_create_pesos'));
      this.STEPS = this.STEPS.concat(['InvestmentStrategyInitialBalance',
        'ConfigureContributionsWealth',
        'SchedulePaymentDateWealth',
      ]);
    } else if (strategy === 'SWSMOD') {
      this.step_names = this.step_names
        .concat(this.translate('steps_budget_tolerance'))
        .concat(this.translate('steps_budget_create'));
      this.STEPS = this.STEPS.concat(['AccessMoreStrategiesRiskTolerance',
        'InvestmentStrategyResult',
        'InvestmentStrategyInitialBalance',
        'ConfigureContributionsWealth',
        'SchedulePaymentDateWealth',
      ]);
    }
  }

  loadEmergencyFund = () => {
    const emergency_fund = this.investor_profile
      .emergency_fund_investor_goal;
    if (emergency_fund) {
      this.investor_goal_state.investor_goal.desired_amount = emergency_fund.desired_amount || 0;
      this.investor_goal_state.emergency_fund.months_of_monthly_incomes = emergency_fund
        .months_of_monthly_incomes || 0;
      this.investor_goal_state.investor_goal.initial_amount = emergency_fund.initial_amount || 0;
      if (emergency_fund.defined_by) {
        this.investor_goal_state.investor_goal.defined_by = emergency_fund.defined_by || '';
        this.investor_goal_state.is_defined_by_contribution = emergency_fund.defined_by
          === 'by_contribution';
        this.investor_goal_state.investor_goal.fixed_time_adjusted = emergency_fund
          .fixed_time_adjusted || 0;
        this.investor_goal_state.investor_goal.monthly_required_amount = emergency_fund
          .monthly_required_amount || 0;
        // by time
        this.investor_goal_state.emergency_fund.months_to_reach_goal = emergency_fund
          .fixed_time_adjusted || 0;
        this.investor_goal_state.emergency_fund.contribution = (emergency_fund
          .monthly_required_amount || 0).toString();
      }
      if (emergency_fund.last_step) {
        const step_founded = this.STEPS.findIndex(
          (step) => emergency_fund.last_step === step,
        );
        if (step_founded) {
          this.current_step = step_founded;
        }
      }
    }
  }

  investorGoalTypeChange = (investor_goal_type: string) => {
    if (investor_goal_type) {
      this.STEPS.length = 2;
      this.step_names.length = 2;
      if (investor_goal_type === 'emergency_fund') {
        this.STEPS = this.STEPS.concat(this.STEPS_EMERGENCY_FUND);
        this.step_names = this.step_names.concat(this.translate('steps_emergency_fund'));
        this.investor_goal_state.investor_goal.icon = 'icon-retirement.svg';
        this.validateContractedProducts();
        this.loadEmergencyFund();
      } else if (investor_goal_type === 'retirement_fund') {
        this.STEPS = this.STEPS.concat(this.STEPS_RETIREMENT_FUND);
        this.step_names = this.step_names.concat(this.translate('steps_retirement_fund'));
        this.investor_goal_state.investor_goal.icon = 'icon-fund.svg';
        this.validateContractedProducts();
      } else {
        this.STEPS = this.STEPS.concat(this.STEPS_CUSTOM);
        this.investor_goal_state.investor_goal.icon = 'icon-person.svg';
        this.step_names = this.step_names.concat(this.translate('steps_custom'));
      }
    }
  }

  setSteps = (link_steps: Array<string>) => {
    this.STEPS = this.STEPS.concat(link_steps);
  }

  addPocketLinkSteps = () => {
    if (!this.STEPS.find((step) => step === 'ScheduleReminderPocket')) {
      const link_steps = ['ScheduleReminderPocket'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.step_names = this.step_names.concat(this.translate('link_steps_pocket'));
      this.setSteps(link_steps);
    }
  }

  removeLinkPocketSteps = () => {
    if (this.STEPS.find((step) => step === 'ScheduleReminderPocket')) {
      this.STEPS.pop();
      this.step_names.pop();
    }
  }

  addWealthLinkSteps = () => {
    if (!this.STEPS.find((step) => step === 'ConfigureContributionsWealth')) {
      const link_steps = ['ConfigureContributionsWealth', 'SchedulePaymentDateWealth'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.step_names = this.step_names.concat(this.translate('link_steps_wealth'));
      this.setSteps(link_steps);
    }
  }

  removeWealthLinkSteps = () => {
    if (this.STEPS.find((step) => step === 'ConfigureContributionsWealth')) {
      this.STEPS.pop();
      this.STEPS.pop();
      this.step_names.pop();
    }
  }

  setAssociatedProduct = (product_name: string) => {
    this.investor_goal_state.associated_product = product_name === 'sowos_wealth'
      ? 'SOWOS Wealth' : 'Sowos Pocket';
    const investment_product = this.product_state_manager.state.items.find(
      (product) => product.name === product_name,
    );
    if (investment_product) {
      this.associated_product_id = investment_product.id;
      this.investor_goal_state.associated_product_id = investment_product.id;
      this.investor_goal_state.associated_product_interest_rate = investment_product.interest_rate;
    }
  }

  validateWealthContractedProduct = () => {
    if (this.state_manager.state.loaded
      && this.state_manager.state.item.has_sowos_wealth_contracted) {
      this.setAssociatedProduct('sowos_wealth');
      this.strategyChange('');
      this.nextStep();
    } else {
      this.view.$emit('contractWealthWarning');
    }
  }

  validateContractedProducts = () => {
    if (this.state_manager.state.loaded
      && this.state_manager.state.item.has_sowos_wealth_contracted) {
      if (this.investor_goal_state.investor_goal_type === 'retirement_fund'
      || (this.investor_goal_state.investor_goal_type === 'custom'
        && this.investor_goal_state.investor_goal.fixed_time_adjusted !== 0
        && this.investor_goal_state.investor_goal.fixed_time_adjusted >= 12
      )) {
        this.removeLinkPocketSteps();
        this.setAssociatedProduct('sowos_wealth');
        this.addWealthLinkSteps();
        this.investor_goal_state.associated_product_contracted = true;
      }
    }

    if (this.state_manager.state.loaded
      && this.state_manager.state.item.has_sowos_pocket_contracted) {
      if (this.investor_goal_state.investor_goal_type === 'emergency_fund'
      || (this.investor_goal_state.investor_goal_type === 'custom'
        && this.investor_goal_state.investor_goal.fixed_time_adjusted < 12
      )) {
        this.removeWealthLinkSteps();
        this.setAssociatedProduct('sowos_pocket');
        this.addPocketLinkSteps();
        this.investor_goal_state.associated_product_contracted = true;
      }
    }

    if (!this.state_manager.state.item.has_sowos_wealth_contracted) {
      if (this.investor_goal_state.investor_goal_type === 'retirement_fund'
      || (this.investor_goal_state.investor_goal_type === 'custom'
        && this.investor_goal_state.investor_goal.fixed_time_adjusted !== 0
        && this.investor_goal_state.investor_goal.fixed_time_adjusted >= 12
      )) {
        this.setAssociatedProduct('sowos_wealth');
        this.investor_goal_state.associated_product_contracted = false;
      }
    }

    if (this.state_manager.state.loaded
      && !this.state_manager.state.item.has_sowos_pocket_contracted) {
      if (this.investor_goal_state.investor_goal_type === 'emergency_fund'
      || (this.investor_goal_state.investor_goal_type === 'custom'
        && this.investor_goal_state.investor_goal.fixed_time_adjusted !== 0
        && this.investor_goal_state.investor_goal.fixed_time_adjusted < 12
      )) {
        this.setAssociatedProduct('sowos_pocket');
        this.investor_goal_state.associated_product_contracted = false;
      }
    }
  }

  loadInvestmentProducts = async () => {
    await this.get_investment_products_query.execute();
  }

  loadInvestorProfile= async () => {
    const investor_profile = await this.get_investor_profile_query.execute();
    this.investor_goal_state.investor_goal.investor_profile_id = investor_profile.id;
    this.investor_profile = investor_profile;
  }

  contractPocket = () => {
    this.view.$emit('contractPocket');
  }

  contractWealth = () => {
    this.view.$emit('contractWealth');
  }

  restoreStateBudget = () => {
    this.more_strategies_state.investor_goal.id = v4();
    this.more_strategies_state.investor_goal.term = 10;
    this.more_strategies_state.strategy.id = '';
  }

  restoreState = () => {
    this.investor_goal_state.is_loading = false;
    this.investor_goal_state.investor_goal_type = '';
    this.investor_goal_state.associated_product_id = '';
    this.investor_goal_state.associated_product = '';
    this.investor_goal_state.associated_product_contracted = false;
    this.investor_goal_state.associated_product_interest_rate = 0;
    // restore investor goal state
    this.investor_goal_state.investor_goal.id = v4();
    this.investor_goal_state.investor_goal.goal_name = '';
    this.investor_goal_state.investor_goal.desired_amount = 0.0;
    this.investor_goal_state.investor_goal.fixed_time_adjusted = 0.0;
    this.investor_goal_state.investor_goal.initial_amount = 0.0;
    this.investor_goal_state.investor_goal.initial_amount_adjusted = 0.0;
    this.investor_goal_state.investor_goal.accumulated_amount = 0.0;
    this.investor_goal_state.investor_goal.monthly_required_amount = 0.0;
    this.investor_goal_state.investor_goal.initial_investment_date = '';
    this.investor_goal_state.investor_goal.final_investment_date = '';
    this.investor_goal_state.investor_goal.wants_years = false;
    this.investor_goal_state.investor_goal.already_created = false;

    this.investor_goal_state.is_defined_by_contribution = false;
    this.investor_goal_state.wants_years = false;
    this.investor_goal_state.goal_created = false;
    this.investor_goal_state.goal_created_and_linked = false;
    this.investor_goal_state.goal_linked_successfully = false;

    // Recurrent contribution
    this.investor_goal_state.current_recurrent_contribution = 0;
    this.investor_goal_state.define_contribution_later = false;
    this.investor_goal_state.recurrent_unassigned_contribution = 0;
    this.investor_goal_state.available_balance = 0;
    this.investor_goal_state.unassigned_balance = 0;
    this.investor_goal_state.total_active_goals_balance = 0;
  }

  initialize = async () => {
    try {
      this.restoreState();
      this.STEPS.length = 1;
      this.step_names.length = 1;
      this.investor_goal_state.is_loading = true;
      await this.loadInvestmentProducts();
      await this.loadInvestorProfile();
      this.investor_goal_state.is_loading = false;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load'));
    }
  }
}
